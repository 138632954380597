<template>
    <v-container>
        <v-row justify="start">
            <v-switch
                class="setting__item" 
                :input-value="showWarning"
                :label="`警告表示: ${showWarning ? 'オン' : 'オフ'}`"
                @change="toggleShowWarning(!showWarning)"
            ></v-switch>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions(['toggleShowWarning']),
    },
    computed: {
        ...mapGetters(['showWarning']),
    }
}
</script>

<style scoped>

.setting__item {
    position: absolute;
    width: 160px; 
    height: 54px;
    text-align: center;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
    opacity: 1;
}


</style>