// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {  // 初期値
    loggedinUser: '',
    selectedCrawler: '',
    selectedRoute: '',
    selectedMode: '',
    showWarning: true,
  },
  mutations: {
    setLoggedinUser(state, payload) {
      state.loggedinUser = payload.name;
    },
    setSelectedCrawler(state, payload) {
      state.selectedCrawler = payload.selectedCrawler;
    },
    deselectedCrawler(state) {
      state.selectedRobot = '';
    },
    setSelectedRoute(state, payload) {
      state.selectedRoute = payload.selectedRoute;
    },
    deselectedRoute(state) {
      state.selectedRoute = '';
    },
    setSelectedMode(state, payload) {
      state.selectedMode = payload.selectedMode;
    },
    deselectedMode(state) {
      state.selectedMode = '';
    },
    setShowWarning(state, value) {
      state.showWarning = value;
    },
    destroy(state) {
      state.loggedinUser = '';
      state.selectedCrawler = '';
      state.selectedRoute = '';
      state.selectedMode = '';
      state.showWarning = false;
    }
  },
  actions: {
    setLoggedinUserAction({ commit }, payload) {
      commit('setLoggedinUser', payload);
    },
    setSelectedCrawlerAction({ commit }, payload) {
      commit('setSelectedCrawler', payload)
    },
    deselectedCrawlerAction({ commit }) {
      commit('deselectedCrawler')
    },
    setSelectedRouteAction({ commit }, payload) {
      commit('setSelectedRoute', payload)
    },
    deselectedRouteAction({ commit }) {
      commit('deselectedRoute')
    },
    setSelectedModeAction({ commit }, payload) {
      commit('setSelectedMode', payload)
    },
    deselectedModeAction({ commit }) {
      commit('deselectedMode')
    },
    destroyAction({ commit }) {
      commit('destroy')
    },
    toggleShowWarning({ commit }, value) {
      commit('setShowWarning', value);
    },
  },
  getters: {
    showWarning: state => state.showWarning,
  },
  modules: {
  },
  plugins: [createPersistedState({storage: window.sessionStorage})]
})
